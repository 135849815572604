import styled from "styled-components";
import { useState } from "react";
import BoxError from "../Boxes/BoxError";
import BoxSuccess from "../Boxes/BoxSuccess";
import * as Web3Onboard from "../Web3Onboard/Web3Onboard";

// ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";

function FormContato(parametros) {

    const languageStrings = {
        "pt-br": {
            "nao_sou_robo": "Por favor confirme que você não é um robô.",
            "erro_geral": "Houve um erro em sua inscrição. Verifique os dados e tente novamente em alguns momentos.",
            "faca_parte": "FAÇA PARTE DA NOSSA COMUNIDADE!",
            "assine_a_lista": "Cadastre-se para saber mais sobre o evento de lançamento da EVE",
            "sucesso": "Inscrição efetuada com sucesso!",
            "placeholders": {
                "nome": "Nome",
                "email": "Email",
                "profissao": "Profissão",
                "empresa": "Empresa",
            },
            "assuntos_de_interesse": "Assuntos de interesse",
            // Com certeza tem forma melhor...
            "assuntos": {
                "tecnologia": "Tecnologia",
                "empreendedorismo": "Empreendedorismo",
                "metaverso": "Metaverso",
                "blockchain": "Blockchain",
                "comunidade": "Comunidade",
                "nft": "NFT",
                "outros": "Outros",
			},
            "enviar": "Enviar",

            "status_wallet": (parametros.wallet ? "CONECTADO" : "CONECTAR WALLET"),
        },
        "en-us": {
            "nao_sou_robo": "Please solve the CAPTCHA.",
            "erro_geral": "There was an error. Please check your data and try again in a few moments.",
            "faca_parte": "BE A PART OF OUR COMMUNITY",
            "assine_a_lista": "Subscribe to know more about our release",
            "sucesso": "Successfully Subscribed!",
            "placeholders": {
                "nome": "Name",
                "email": "Email",
                "profissao": "Occupation",
                "empresa": "Company",
            },
            "assuntos_de_interesse": "Topics of Interest",
            "assuntos": {
                "tecnologia": "Tech",
                "empreendedorismo": "Entrepreneurship",
                "metaverso": "Metaverse",
                "blockchain": "Blockchain",
                "comunidade": "Community",
                "nft": "NFT",
                "outros": "Others",
            },
            "enviar": "Subscribe",

            "status_wallet": (parametros.wallet ? "CONNECTED" : "CONNECT WALLET"),
		}
	}

    let email, nome, profissao, empresa, assuntos;

    // Feio
    const [captchaStatus, setCaptchaStatus] = useState(null);

    const [status, setStatus] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    if (parametros.aberto) {
        return (
            <></>
        );
    }

    async function enviarDados(dados) {
        const content = {
            "method": "POST",
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            "body": new URLSearchParams(dados),
        }

        let response;

        await fetch(parametros.endpoint, content)
            .then(r => r.json())
            .then(r => response = r)
            .catch(err => { console.error(err); response = { success: false }; });

        return response;
    }

    function ValidateForm(c) {
        if (c === null) {
            setErrorMessage(languageStrings[parametros.language].nao_sou_robo);
            setStatus("error");
            return false;
        }

        var assuntosSelecionados = [...assuntos.selectedOptions].map(opt => opt.value).join(",");
        if (
            email.value &&
            email.value.indexOf("@") > -1
            && nome.value
            && profissao.value
            && empresa.value
            && assuntosSelecionados
            && captchaStatus !== null) {
            console.log("Form Validado");

            var formData = {
                "fields[email]": email.value,
                "fields[nome]": nome.value,
                "fields[profissao]": profissao.value,
                "fields[empresa]": empresa.value,
                "fields[interesse]": [...assuntos.selectedOptions].map(opt => opt.value).join(","),
                "fields[comunidade]": "Sim",
                "fields[origem_form]": "Newsletter",
                "g-captcha-response": captchaStatus,
            }

            // Colocar a carteira junto.
            console.log(parametros.wallet);

            if (parametros.wallet && parametros.wallet.accounts.length > 0) {
                const accountConectada = parametros.wallet.accounts;

                if (accountConectada.length > 0) {
                    formData["fields[wallet_address]"] = accountConectada[0].address;

                    if (parametros.wallet.chains[0].id == "0x1") {
                        formData["fields[saldo_carteira]"] = accountConectada[0].balance.ETH;
					}

                    //formData["fields[saldo_carteira]"] = accountConectada[0].balance[parametros.wallet.chains[0].token];
                    formData["fields[rede]"] = (parametros.wallet.chains[0].label ? parametros.wallet.chains[0].label : parametros.wallet.chains[0].id);
                    formData["fields[wallet_provider]"] = parametros.wallet.label;
                }
                else {
                    console.log("Provedor de wallet conectou, mas não tem um endereço associado?");
                }

            }
            else {
                console.log("Wallet NÃO conectada");
            }

            return formData;
        }
        else {
            console.log("Form não foi validado");
            setErrorMessage(languageStrings[parametros.language].erro_geral);
            setStatus("error");
            return false;
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setStatus("sending");

        var r = ValidateForm(captchaStatus);

        if (r !== false) {
            var resultado = await enviarDados(r);
            console.log(resultado);

            if (resultado.success === true) {
                setStatus("success");
            }
            else {
                setErrorMessage(languageStrings[parametros.language].erro_geral);
                setStatus("error");
            }
        }
        else {

        }
    }

    async function connectAction() {
        await parametros.connect();

        let w = Web3Onboard.web3Onboard.state.get();
        console.log(w);
    }

    function captchaChanged(v) {
        console.log("Captcha = ", v);
        setCaptchaStatus(v);
        return;
    }

    return (
        <form onSubmit={handleSubmit}>
            <section id="contact" className="flexCol page-block; padding:0" style={{ background: "#6E00A8" }}>
                <h2 style={{ width: "70%", textAlign: "center" }}>
                    {languageStrings[parametros.language].faca_parte}
                </h2>
                <ConnectButton className="mint-button connect-button"
                    onClick={connectAction}
                >
                    {languageStrings[parametros.language].status_wallet}
                </ConnectButton>
                <h4 style={{ width: "70%", textAlign: "center" }}>
                    {languageStrings[parametros.language].assine_a_lista}
                </h4>
                {status === "sending" && <div style={{ color: "blue", display: "none" }}>sending...</div>}
                {status === "error" && (
                    <BoxError mensagem={errorMessage} aberto="true" setStatus={setStatus} />
                )}
                {status === "success" && (
                    <BoxSuccess mensagem={languageStrings[parametros.language].sucesso} aberto="true" />
                )}
                <div className="flexRow contact_line_full">
                    <input
                        className="form-input"
                        style={{ width: "100%" }}
                        type="text"
                        ref={node => (nome = node)}
                        placeholder={languageStrings[parametros.language].placeholders.nome}
                    />
                </div>
                <div className="flexRow contact_line_full">
                    <input
                        className="form-input"
                        style={{ margin: "1em 0", width: "100%" }}
                        type="text"
                        ref={node => (email = node)}
                        placeholder={languageStrings[parametros.language].placeholders.email}
                    />
                </div>
                <div className="flexRow contact_line_full">
                    <input
                        className="form-input"
                        style={{ width: "47.5%" }}
                        type="text"
                        ref={node => (profissao = node)}
                        placeholder={languageStrings[parametros.language].placeholders.profissao}
                    />
                    <input
                        className="form-input"
                        style={{ marginLeft: "auto", width: "47.5%" }}
                        type="text"
                        ref={node => (empresa = node)}
                        placeholder={languageStrings[parametros.language].placeholders.empresa}
                    />
                </div>
                <h4 style={{ textAlign: "center" }}>{languageStrings[parametros.language].assuntos_de_interesse}</h4>
                <select
                    className="form-input contact_line"
                    style={{ height: "auto", marginTop: 10 }}
                    ref={node => (assuntos = node)}
                    multiple="multiple"
                >
                    <option value="Tecnologia">{languageStrings[parametros.language].assuntos.tecnologia}</option>
                    <option value="Empreendedorismo">{languageStrings[parametros.language].assuntos.empreendedorismo}</option>
                    <option value="Metaverso">{languageStrings[parametros.language].assuntos.metaverso}</option>
                    <option value="Blockchain">{languageStrings[parametros.language].assuntos.blockchain}</option>
                    <option value="Comunidade">{languageStrings[parametros.language].assuntos.comunidade}</option>
                    <option value="NFT">{languageStrings[parametros.language].assuntos.nft}</option>
                    <option value="Outros">{languageStrings[parametros.language].assuntos.outros}</option>
                </select>
                <p>&nbsp;</p>
                <ReCAPTCHA
                    onChange={captchaChanged}
                    sitekey="6LcW5iYhAAAAAKJjJ0TSO7HtmRX1SYpGxZP8REMo"
                />
                <button type="submit" id="form_button_submit">
                    {languageStrings[parametros.language].enviar}
                </button>
            </section>
        </form>
    );
}

export default FormContato;

const ConnectButton = styled.div`
`;