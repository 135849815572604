import styled from "styled-components";

import * as Web3Onboard from "../Web3Onboard/Web3Onboard";
import { useState, useEffect } from "react";
import axios from "axios";

function WalletSubscribe({ language, aberto, connect, wallet }) {

    const { endpointCapturaWallet } = require("../../secrets.json");

    const languageStrings = {
        "pt-br": {
            "objetivo": "Conecte sua Carteira para se inscrever na nossa Allowlist",
            "carteira_conectada": "Carteira Conectada",
            "carteira_enviada": "Parabéns! Sua vaga na nossa Allowlist está garantida. LFG!!",
            "carteira_duplicidade": "Carteira já está na Allowlist",
            "carteira_erro": "Houve um problema ao adicionar a carteira",
            "status_wallet": (wallet ? "CONECTADO" : "CONECTAR WALLET"),
        },
        "en-us": {
            "status_wallet": (wallet ? "CONNECTED" : "CONNECT WALLET"),
            "objetivo": "Connect your Wallet to subscribe to our Allowlist",
            "carteira_conectada": "Wallet Connected",
            "carteira_enviada": "Congratulations! Your spot on our Allowlist is guaranteed. LFG!!",
            "carteira_duplicidade": "Wallet already in the Allowlist",
            "carteira_erro": "There was an error when adding the wallet to the Allowlist",
        }
    }

    const [mensagem, setMensagem] = useState(languageStrings[language].objetivo);

    useEffect(() => { setMensagem(wallet ? languageStrings[language].carteira_enviada : languageStrings[language].objetivo); }, []);

    async function connectAction() {
        await connect();

        let w = Web3Onboard.web3Onboard.state.get();
        console.log(w);

        if (w.wallets.length > 0) {
            const accountConectada = w.wallets[0].accounts;

            if (accountConectada.length > 0) {
                const enderecoWalletConectada = accountConectada[0].address;
                const saldo = accountConectada[0].balance.ETH;
                const rede = (w.chains[0].label ? w.chains[0].label : w.chains[0].id);
                const wallet_provider = (w.wallets[0].label ? w.wallets[0].label : "");

                // Mandar dados
                let response;
                response = await enviarDados(enderecoWalletConectada, saldo, rede, wallet_provider);

                if (response.success == true) {
                    // Ações para sucesso
                    setMensagem(languageStrings[language].carteira_enviada);
                }
                else {
                    // Ações para falha
                    setMensagem(languageStrings[language].carteira_erro);
				}
            }
            else {
                console.log("Provedor de wallet conectou, mas não tem um endereço associado?");
			}

        }
        else {
            console.log("Wallet NÃO conectada");
		}
    }

    async function enviarDados(endereco, saldo, rede, wallet_provider) {
        const dados = {
            "fields[email]": "blumatech+" + endereco + "@blumatech.com",
            "fields[wallet_address]": endereco,
            "fields[saldo_carteira]": saldo,
            "fields[rede]": rede,
            "fields[wallet_provider]": wallet_provider,
        }

        const content = {
            "method": "POST",
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            "body": new URLSearchParams(dados),
        }

        let response;

        await fetch(endpointCapturaWallet, content)
            .then(r => r.json())
            .then(r => response = r)
            .catch(err => { console.error(err); response = { success: false }; });

        return response;
	}

    if (aberto) {

        return (
            <div style={{ background: "red", width: "100%"}}>
                <section className="flexCol mint-background page-block">
                    <h2 style={{ color: "#FFFFFF" }}>{mensagem}</h2>
                    <ConnectButton className="mint-button connect-button"
                        onClick={connectAction}
                    >
                        {languageStrings[language].status_wallet}
                    </ConnectButton>
                </section>
            </div>
        );
    }
    else {
        return (
            <></>
        );
    }
}

const ConnectButton = styled.div`
`;

export default WalletSubscribe;