function Founders(parametros) {

	if (parametros.aberto) {
		return (
			<></>
		);
	}

	return (
		<section id="founders" className="flexCol darkblue-background page-block">
			<h1>FOUNDERS</h1>
			<div
				className="flexRow desktop"
				style={{
					margin: "auto",
					width: "95%",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<div className="founder-box">
					<img src="../images/founders/founder1.webp" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://twitter.com/explicaana"
							target="_blank"
							className="fab fa-twitter"
						/>
						<a
							href="https://www.linkedin.com/in/ana-laura-magalh%C3%A3es-barata-16076443/"
							target="_blank"
							className="fab fa-linkedin"
						/>
						<a
							href="https://www.instagram.com/analauramb/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Ana Laura</h2>
				</div>
				<div className="founder-box">
					<img src="../images/founders/founder2.webp" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://twitter.com/cintianft"
							target="_blank"
							className="fab fa-twitter"
						/>
						<a
							href="https://www.linkedin.com/in/cintialiviaferreira/"
							target="_blank"
							className="fab fa-linkedin"
						/>
						<a
							href="https://www.instagram.com/cintiaferreira.eve/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Cintia Ferreira</h2>
				</div>
				<div className="founder-box">
					<img src="../images/founders/founder3.png" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://twitter.com/ElektraOficial"
							target="_blank"
							className="fab fa-twitter"
						/>
						<a
							href="https://www.linkedin.com/in/liviaelektra/"
							target="_blank"
							className="fab fa-linkedin"
						/>
						<a
							href="https://www.instagram.com/elektra/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Elektra</h2>
				</div>
				<div className="founder-box">
					<img src="../images/founders/founder4.png" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://twitter.com/kim_farrell"
							target="_blank"
							className="fab fa-twitter"
						/>
						<a
							href="https://www.linkedin.com/in/kim-farrell/"
							target="_blank"
							className="fab fa-linkedin"
						/>
						<a
							href="https://www.instagram.com/kimsoueu/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Kim Farrell</h2>
				</div>
				<div className="founder-box">
					<img src="../images/founders/founder5.png" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://twitter.com/NinaSilvaPerfil"
							target="_blank"
							className="fab fa-twitter"
						/>
						<a
							href="https://www.linkedin.com/in/marina-silva-profile/"
							target="_blank"
							className="fab fa-linkedin"
						/>
						<a
							href="https://www.instagram.com/ninasilvaperfil/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Nina Silva</h2>
				</div>
			</div>
			<div
				className="flexRow desktop"
				style={{
					margin: "auto",
					width: "95%",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<div className="founder-box">
					<img src="../images/founders/founder6.webp" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://www.linkedin.com/in/nubia-mota/"
							target="_blank"
							className="fab fa-linkedin"
						/>
						<a
							href="https://www.instagram.com/nubiamota/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Núbia Mota</h2>
				</div>
				<div className="founder-box">
					<img src="../images/founders/founder7.png" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://twitter.com/Paula_Lima"
							target="_blank"
							className="fab fa-twitter"
						/>
						<a
							href="https://www.instagram.com/paulalima/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Paula Lima</h2>
				</div>
				<div className="founder-box">
					<img src="../images/founders/founder8.png" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://www.linkedin.com/in/roberta-antunes-56578b39/"
							target="_blank"
							className="fab fa-linkedin"
						/>
						<a
							href="https://www.instagram.com/betaantunes11/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Roberta Antunes</h2>
				</div>
				<div className="founder-box">
					<img src="../images/founders/founder9.png" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://twitter.com/samaracosta00"
							target="_blank"
							className="fab fa-twitter"
						/>
						<a
							href="https://www.instagram.com/samaracosta/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Samara Costa</h2>
				</div>
				<div className="founder-box">
					<img src="../images/founders/founder10.webp" className="founder" />
					<div className="founder-network" style={{ visibility: "visible" }}>
						<a
							href="https://twitter.com/SIMONESSANCHO"
							target="_blank"
							className="fab fa-twitter"
						/>
						<a
							href="https://www.linkedin.com/in/simone-sancho-b7aa9a12/"
							target="_blank"
							className="fab fa-linkedin"
						/>
						<a
							href="https://www.instagram.com/simonesancho/"
							target="_blank"
							className="fab fa-instagram"
						/>
					</div>
					<h2>Simone Sancho</h2>
				</div>
			</div>
			<div className="mobile" style={{ height: "auto", width: "95%" }}>
				<div className="founders-scroll">
					<div className="founders-mobile">
						<img src="../images/founders/founder1.webp" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://twitter.com/explicaana"
								target="_blank"
								className="fab fa-twitter"
							/>
							<a
								href="https://www.linkedin.com/in/ana-laura-magalh%C3%A3es-barata-16076443/"
								target="_blank"
								className="fab fa-linkedin"
							/>
							<a
								href="https://www.instagram.com/analauramb/"
								target="_blank"
								className="fab fa-instagram"
							/>
						</div>
						<h2>Ana Laura</h2>
					</div>
					<div className="founders-mobile">
						<img src="../images/founders/founder2.webp" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://twitter.com/cintianft"
								target="_blank"
								className="fab fa-twitter"
							/>
							<a
								href="https://www.linkedin.com/in/cintialiviaferreira/"
								target="_blank"
								className="fab fa-linkedin"
							/>
							<a
								href="https://www.instagram.com/cintiaferreira.eve/"
								target="_blank"
								className="fab fa-instagram"
							/>
						</div>
						<h2>Cintia Ferreira</h2>
					</div>
					<div className="founders-mobile">
						<img src="../images/founders/founder3.png" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://twitter.com/ElektraOficial"
								target="_blank"
								className="fab fa-twitter"
							/>
							<a
								href="https://www.linkedin.com/in/liviaelektra/"
								target="_blank"
								className="fab fa-linkedin"
							/>
							<a
								href="https://www.instagram.com/elektra/"
								target="_blank"
								className="fab fa-instagram"
							/>
						</div>
						<h2>Elektra</h2>
					</div>
					<div className="founders-mobile">
						<img src="../images/founders/founder4.png" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://twitter.com/kim_farrell"
								target="_blank"
								className="fab fa-twitter"
							/>
							<a
								href="https://www.linkedin.com/in/kim-farrell/"
								target="_blank"
								className="fab fa-linkedin"
							/>
							<a
								href="https://www.instagram.com/kimsoueu/"
								target="_blank"
								className="fab fa-instagram"
							/>
						</div>
						<h2>Kim Farrell</h2>
					</div>
					<div className="founders-mobile">
						<img src="../images/founders/founder5.png" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://twitter.com/NinaSilvaPerfil"
								target="_blank"
								className="fab fa-twitter"
							/>
							<a
								href="https://www.linkedin.com/in/marina-silva-profile/"
								target="_blank"
								className="fab fa-linkedin"
							/>
							<a
								href="https://www.instagram.com/ninasilvaperfil/"
								target="_blank"
								className="fab fa-instagram"
							/>
						</div>
						<h2>Nina Silva</h2>
					</div>
					<div className="founders-mobile">
						<img src="../images/founders/founder6.webp" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://www.linkedin.com/in/nubia-mota/"
								target="_blank"
								className="fab fa-linkedin"
							/>
							<a
								href="https://www.instagram.com/nubiamota/"
								target="_blank"
								className="fab fa-instagram"
							/>
						</div>
						<h2>Núbia Mota</h2>
					</div>
					<div className="founders-mobile">
						<img src="../images/founders/founder7.png" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://twitter.com/Paula_Lima"
								target="_blank"
								className="fab fa-twitter"
							/>
							<a
								href="https://www.instagram.com/paulalima/"
								target="_blank"
								className="fab fa-instagram"
							/>
						</div>
						<h2>Paula Lima</h2>
					</div>
					<div className="founders-mobile">
						<img src="../images/founders/founder8.webp" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://www.linkedin.com/in/roberta-antunes-56578b39/"
								target="_blank"
								className="fab fa-linkedin"
							/>
							<a
								href="https://www.instagram.com/betaantunes11/"
								target="_blank"
								className="fab fa-instagram"
							/>
						</div>
						<h2>Roberta Antunes</h2>
					</div>
					<div className="founders-mobile">
						<img src="../images/founders/founder9.png" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://twitter.com/samaracosta00"
								target="_blank"
								className="fab fa-twitter"
							/>
							<a
								href="https://www.instagram.com/samaracosta/"
								target="_blank"
								className="fab fa-instagram"
								alt="Instagram"
							/>
						</div>
						<h2>Samara Costa</h2>
					</div>
					<div className="founders-mobile">
						<img src="../images/founders/founder10.webp" className="founder" />
						<div className="founder-network" style={{ visibility: "visible" }}>
							<a
								href="https://twitter.com/SIMONESSANCHO"
								target="_blank"
								className="fab fa-twitter"
							/>
							<a
								href="https://www.linkedin.com/in/simone-sancho-b7aa9a12/"
								target="_blank"
								className="fab fa-linkedin"
							/>
							<a
								href="https://www.instagram.com/simonesancho/"
								target="_blank"
								className="fab fa-instagram"
								alt="Instagram"
							/>
						</div>
						<h2>Simone Sancho</h2>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Founders;