// ------------------------------------
// Versão local EN-US
// ------------------------------------

import styled from "styled-components";

import {
    init,
    useConnectWallet,
    useSetChain,
    useWallets,
} from "@web3-onboard/react";

import Web3 from "web3";
import { useState, useRef, useEffect } from "react";

import Swal from "sweetalert2";
import BlocoMintagem from "../Component/MintPanel/BlocoMintagem";
import WalletSubscribe from "../Component/WalletSubscribe/WalletSubscribe";
import * as Web3Onboard from "../Component/Web3Onboard/Web3Onboard";
import FormContato from "../Component/FormContato/FormContato";
import BoxProgress from "../Component/Boxes/BoxProgress";
import Time from "../Component/Time/Time";
import Founders from "../Component/Founders/Founders";
import Apoiadores from "../Component/Apoiadores/Apoiadores";
import Media from "../Component/Media/Media";
import Roadmap from "../Component/Roadmap/Roadmap";
import Pilares from "../Component/Pilares/Pilares";
import Rodape from "../Component/Outros/Rodape";
import Topo from "../Component/Outros/Topo";
import About from "../Component/Outros/About";

// Google
import ReactGA from "react-ga";
ReactGA.initialize("UA-225343577-1");
ReactGA.pageview(window.location.pathname + window.location.search);

// Mint está aberto?
const aberto = false;

// Form da comunidade
const { endpointFormInscricao } = require("../secrets.json");

const App_enus = ({ }) => {
    let allowlistData = "";

    let webjs = new Web3(new Web3.providers.HttpProvider(Web3Onboard.rpcInfura));
    let contract = new webjs.eth.Contract(Web3Onboard.MainContractMetaData, Web3Onboard.mainContractAddress);

    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [{ chains, connectedChain, settingChain }, setChain] = useSetChain();
    const [tokenState, setTokenState] = useState(null);
    const [limitPerWalletState, setlimitPerWalletState] = useState(null);
    const [remainingSupplyState, setRemainingSupplyState] = useState("(loading)");
    const [MaxMintsAvailable, setMaxMintsAvailable] = useState("(loading)");
    const [isPublicMintActive, setIsPublicMintActive] = useState(false);
    const [priceInEther, setPriceInEther] = useState("(loading)");
    const [priceInWei, setPriceInWei] = useState(null);
    const connectedWallets = useWallets();

    const [processingState, setProcessingState] = useState(false);
    const [processingMessage, setProcessingMessage] = useState("");

    const { unsubscribe } = Web3Onboard.web3Onboard.state.select("wallets").subscribe(update => processWalletEvent(update));

    function processWalletEvent(walletEvent) {
        let estado = "";

        if (walletEvent.length) {
            // Conectado a alguma coisa
            estado = "Conectado a: " + walletEvent[0].label;
        }
        else {
            // Desconectado de tudo
            estado = "Desconectado";
            setTokenState(null);
        }

        console.log("Novo estado da carteira: " + estado);
    }

    const updateSupplyInfo = async () => {
        // Não gastar recursos se não está aberto o processo!
        if (!aberto) {
            return;
        }

        // Good names
        //const MaxMintsAvailable = await contract.methods.maximumCollectionSize().call();
        //const priceInWei = await contract.methods.mintPrice().call();
        //const limitPerWallet = await contract.methods.limitPerWallet().call();

        // Dumb names
        const MaxMintsAvailable = await contract.methods.MaxMintsAvailable().call();
        const priceInWei = await contract.methods.publicMintStagePrice().call();

        let limitPerWallet;

        if (wallet) {
            limitPerWallet = await contract.methods.publicMintStageLimitPerWallet(wallet.accounts[0].address).call();
        }
        else {
            limitPerWallet = await contract.methods.publicMintStageLimitPerWallet().call();
        }

        const amountMinted = await contract.methods.totalSupply().call();
        const isPublicMintActive = await contract.methods.isPublicMintStageActive().call();
        const priceInEther = webjs.utils.fromWei(priceInWei);

        const remaining = MaxMintsAvailable - amountMinted;

        console.log({ remaining });
        console.log({ amountMinted });
        console.log({ MaxMintsAvailable });
        console.log({ priceInEther });
        console.log({ priceInWei });
        console.log({ limitPerWallet });

        setRemainingSupplyState(remaining);
        setMaxMintsAvailable(MaxMintsAvailable);
        setIsPublicMintActive(isPublicMintActive);
        setPriceInEther(priceInEther);
        setPriceInWei(priceInWei);
        setlimitPerWalletState(limitPerWallet);
    };

    async function updateTokensOfOwner() {
        // Não gastar recursos se não está aberto o processo!
        if (!aberto) {
            return;
        }

        webjs = new Web3(wallet.provider);

        let senderAddress = wallet.accounts[0].address;
        let tokensOfOwner = 0;

        //console.log(senderAddress, "Endereço Conectado");

        tokensOfOwner = await contract.methods.tokensOfOwner(senderAddress).call();
        //console.log(tokensOfOwner.length);

        setTokenState(tokensOfOwner.length);
    }

    useEffect(() => {
        updateSupplyInfo();

        if (wallet) {
            updateTokensOfOwner();
        }
    }, [wallet]);

    const mint = async (_batchSize) => {
        if (!wallet) {
            Swal.fire(
                "Not Connected!",
                "Please Connect Your Wallet With the Connect Button",
                "error"
            );
            return;
        }

        setProcessingMessage("Verifying Wallet data...");
        setProcessingState(true);

        // Atualizar mais uma vez.
        updateSupplyInfo();

        if (!isPublicMintActive) {
            setProcessingState(false);
            Swal.fire("Mint Not Active Yet!");
            return false;
        }

        let webjs = new Web3(wallet.provider);
        let contract = new webjs.eth.Contract(Web3Onboard.MainContractMetaData, Web3Onboard.mainContractAddress);

        // Teste da rede que está selecionada.
        let chain = await webjs.eth.getChainId();

        console.log(Web3Onboard.web3Onboard.state.get().chains[0]);

        if (chain != Number(Web3Onboard.web3Onboard.state.get().chains[0].id)) {
            setProcessingState(false);
            Swal.fire(
                "Incorrect Network",
                "Please select the correct Network (" + Web3Onboard.web3Onboard.state.get().chains[0].label + ") in your Wallet provider.",
                "error"
            );
            return;
        }

        const totalPrice = priceInWei * _batchSize;
        const balance = webjs.utils.toWei(connectedWallets[0].accounts[0].balance.ETH);

        console.log({ balance });
        console.log({ totalPrice });
        console.log({ priceInWei });

        if (balance < totalPrice) {
            setProcessingState(false);
            Swal.fire(
                "Insufficent Balance",
                "You do not have sufficent balance to mint the required amount",
                "error"
            );
            return;
        }

        let senderAddress = wallet.accounts[0].address;
        let errored = false;

        await contract.methods.batchMint(_batchSize).estimateGas(
            { from: senderAddress, value: totalPrice },
            (error, result) => {
                if (error) {
                    console.log(error);
                    let str = String(error);
                    str = str.substring(str.indexOf("{") + 1);
                    str = str.substring(str.indexOf(":") + 1);
                    let position = str.lastIndexOf("}");
                    str = str.substring(0, position - 1);
                    console.log(str, "rawError");

                    let jsonErr = JSON.parse(str);
                    let revertMessage = jsonErr.message;
                    revertMessage = revertMessage
                        .toLowerCase()
                        .split(" ")
                        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                        .join(" ");

                    if (jsonErr) {
                        console.log(jsonErr, "jsonErr");
                        setProcessingState(false);
                        Swal.fire("Transaction Not Completed", revertMessage, "error");
                        errored = true;
                        return;
                    }
                }

                if (result) console.log("result");
            }
        );

        if (errored) {
            return;
        }

        setProcessingMessage("Minting... Follow your Wallet provider instructions.");

        try {
            let result = await contract.methods
                .batchMint(_batchSize)
                .send({ from: senderAddress, value: totalPrice })
                .then(function (receipt) {
                    console.log(receipt);
                });

            updateSupplyInfo();
            updateTokensOfOwner();

            setProcessingState(false);
        } catch (e) {
            let swalError = {
                icon: "error",
                title: "Something went wrong",
                text: "Did you cancel the transaction?",
            };

            Swal.fire(swalError);
            console.log(e);
            setProcessingState(false);
        }
    };

    return (
        <>
            <Topo language="en-us" exibirBloco={!aberto} exibirBotaoComunidade={true} />
            <BlocoMintagem
                language="en-us"
                isMintPass="true"
                aberto={aberto}
                tokenState={tokenState}
                MaxMintsAvailable={MaxMintsAvailable}
                remainingSupplyState={remainingSupplyState}
                isPublicMintActive={isPublicMintActive}
                limitPerWalletState={limitPerWalletState}
                priceInEther={priceInEther}
                connect={connect}
                wallet={wallet}
                allowlistData={allowlistData}
                mint={mint} />
            <Topo language="en-us" exibirBloco={aberto} exibirBotaoComunidade={true} />
            <WalletSubscribe
                language="en-us"
                aberto={false}
                connect={connect}
                wallet={wallet}
            />
            <Roadmap language="en-us" aberto={false} />
            <Pilares language="en-us" aberto={false} />
            <Founders language="en-us" aberto={false} />
            <Time language="en-us" aberto={false} />
            <About language="en-us" aberto={false} />
            <Apoiadores language="en-us" aberto={false} />
            <FormContato
                endpoint={endpointFormInscricao}
                wallet={wallet}
                aberto={false}
                language="en-us"
                connect={connect}
            />
            <Media language="en-us" aberto={false} />
            <Rodape language="en-us" />
            {/* POP UPS*/}
            <BoxProgress aberto={processingState} mensagem={processingMessage} />
        </>
    );
}

export default App_enus;

const ConnectButton = styled.div`
`;