function Time(parametros) {

	if (parametros.aberto) {
		return (
			<></>
		);
	}

	if (parametros.language == "en-us") {
		return (
			<section id="founders" className="flexCol darkblue-background page-block">
				<h1>TIME</h1>
				<div
					className="flexRow desktop"
					style={{
						margin: "auto",
						width: "95%",
						alignItems: "baseline",
						justifyContent: "center"
					}}
				>
					<div className="founder-box time">
						<img src="../images/Time/Time1.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Alex</h2>
						<h4>Tech</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time2.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Bruno</h2>
						<h4>Tech</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time3.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Caio</h2>
						<h4>Marketing</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time4.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Cesar</h2>
						<h4>Financial</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time5.png" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Duda</h2>
						<h4>Growth</h4>
					</div>
				</div>
				<div
					className="flexRow desktop"
					style={{
						margin: "auto",
						width: "95%",
						alignItems: "baseline",
						justifyContent: "center"
					}}
				>
					<div className="founder-box time">
						<img src="../images/Time/Time14.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Edimo</h2>
						<h4>Compliance</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time7.png" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Jacke</h2>
						<h4>Head of<br/>Communication</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time8.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Marcelo</h2>
						<h4>Development</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time10.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Paul </h2>
						<h4>Tech</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time15.jpg" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Jefferson</h2>
						<h4>Community<br/>Manager</h4>
					</div>
				</div>
				<div className="mobile" style={{ width: "95%" }}>
					<div className="founders-scroll">
						<div className="time-mobile">
							<img src="../images/Time/Time1.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Alex</h2>
							<h4>
								Tech
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time2.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Bruno</h2>
							<h4>
								Tech
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time3.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Caio</h2>
							<h4>Marketing</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time4.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Cesar</h2>
							<h4>
								Financial
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time5.png" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Duda</h2>
							<h4>
								Growth
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time14.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Edimo</h2>
							<h4>
								Compliance
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time7.png" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Jacke</h2>
							<h4>
								Head de Comunicação
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time8.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Marcelo</h2>
							<h4>
								Development
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time10.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Paul</h2>
							<h4>
								Tech
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time15.jpg" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Jefferson</h2>
							<h4>Community Manager<br /><br /></h4>
						</div>
					</div>
				</div>
			</section>
		);
	}
	else {
		return (
			<section id="founders" className="flexCol darkblue-background page-block">
				<h1>TIME</h1>
				<div
					className="flexRow desktop"
					style={{
						margin: "auto",
						width: "95%",
						alignItems: "baseline",
						justifyContent: "center"
					}}
				>
					<div className="founder-box time">
						<img src="../images/Time/Time1.webp" className="time-img" alt="Alex, Tech" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Alex</h2>
						<h4>Tech</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time2.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Bruno</h2>
						<h4>Tech</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time3.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Caio</h2>
						<h4>Marketing</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time4.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Cesar</h2>
						<h4>Financeiro</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time5.png" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Duda</h2>
						<h4>Growth</h4>
					</div>
				</div>
				<div
					className="flexRow desktop"
					style={{
						margin: "auto",
						width: "95%",
						alignItems: "baseline",
						justifyContent: "center"
					}}
				>
					<div className="founder-box time">
						<img src="../images/Time/Time14.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Edimo</h2>
						<h4>Compliance</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time7.png" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Jacke</h2>
						<h4>Head de<br/>Comunicação</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time8.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Marcelo</h2>
						<h4>Development</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time10.webp" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Paul </h2>
						<h4>Tech</h4>
					</div>
					<div className="founder-box time">
						<img src="../images/Time/Time15.jpg" className="time-img" />
						<div className="founder-network" style={{ visibility: "hidden" }}>
							<a href="" target="_blank" className="fab fa-discord" />
							<a href="" target="_blank" className="fab fa-twitter" />
							<a href="" target="_blank" className="fab fa-linkedin" />
							<a href="" target="_blank" className="fab fa-instagram" />
						</div>
						<h2>Jefferson</h2>
						<h4>Community<br/>Manager</h4>
					</div>
				</div>
				<div className="mobile" style={{ width: "95%" }}>
					<div className="founders-scroll">
						<div className="time-mobile">
							<img src="../images/Time/Time1.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Alex</h2>
							<h4>
								Tech
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time2.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Bruno</h2>
							<h4>
								Tech
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time3.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Caio</h2>
							<h4>Marketing<br /><br /></h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time4.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Cesar</h2>
							<h4>
								Financeiro
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time5.png" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Duda</h2>
							<h4>
								Growth
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time14.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Edimo</h2>
							<h4>
								Compliance
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time7.png" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Jacke</h2>
							<h4>
								Head of Communication
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time8.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Marcelo</h2>
							<h4>
								Development
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time10.webp" className="time-img" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Paul</h2>
							<h4>
								Tech
								<br />
								<br />
							</h4>
						</div>
						<div className="time-mobile">
							<img src="../images/Time/Time15.jpg" className="time-img" alt="Jefferson, Community Manager" />
							<div className="founder-network" style={{ visibility: "hidden" }}>
								<a href="" target="_blank" className="fab fa-discord" />
								<a href="" target="_blank" className="fab fa-twitter" />
								<a href="" target="_blank" className="fab fa-linkedin" />
								<a href="" target="_blank" className="fab fa-instagram" />
							</div>
							<h2>Jefferson</h2>
							<h4>Community Manager<br /><br /></h4>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Time;