
function Pilares(parametros) {

    if (parametros.aberto) {
        return (
            <></>
        );
    }

	if (parametros.language == "pt-br") {
		return (
            <section
                className="flexCol pink-background page-block"
                style={{ background: "#6E00A8" }}
            >
                <h1>Pilares</h1>
                <div className="flexRow" style={{ width: "100%" }}>
                    <div className="pilar">
                        <img src="../images/pilar1.webp" />
                        <h2>Educação e Liderança</h2>
                        <p>
                            Compromisso com a geração e o compartilhamento de conteúdo livre,
                            aberto, dinâmico, fundamentado e de alta qualidade, de modo a
                            contribuir para a formação e a capacitação de mulheres através de
                            experiências exclusivas de mentorias e imersões online e presenciais.
                        </p>
                    </div>
                    <div className="pilar">
                        <img src="../images/pilar2.webp" />
                        <h2>Independência Financeira</h2>
                        <p>
                            Promover a independência financeira de todas as mulheres interessadas
                            em atuar neste mercado, aproximando-as de oportunidades reais e
                            estimulando o seu pleno desenvolvimento, seja posicionando
                            adequadamente seus produtos no mercado mundial, seja gerando renda e
                            realizando lucros sobre investimentos.
                        </p>
                    </div>
                    <div className="pilar">
                        <img src="../images/pilar3.webp" />
                        <h2>Filantropia e Give Back</h2>
                        <p>
                            Total compromisso em filantropia e Give Back dando suporte a projetos
                            locais e mundiais que colaborem com o combate às desigualdades de
                            gênero e apoiem o amplo desenvolvimento das mulheres nos mais variados
                            setores.
                        </p>
                    </div>
                </div>
            </section>
			);
	}
	else {
		return (
            <section
                className="flexCol pink-background page-block"
                style={{ background: "#6E00A8" }}
            >
                <h1>Pillars</h1>
                <div className="flexRow" style={{ width: "100%" }}>
                    <div className="pilar">
                        <img src="../images/pilar1.webp" />
                        <h2>Education and Leadership</h2>
                        <p>
                            We are committed with future generations, so that we can contribute
                            with the capacitation and formation of women through exclusive
                            mentorships, access and courses in different areas that our founders
                            and partners have expertise, both online and IRL.
                        </p>
                    </div>
                    <div className="pilar">
                        <img src="../images/pilar2.webp" />
                        <h2>Financial Independence</h2>
                        <p>
                            Through EVE foundation many projects will be funded so women can
                            achieve their dreams and start their own businesses.
                            <br /> Also, part of the sales and royalties will be reverted to the
                            community.
                        </p>
                    </div>
                    <div className="pilar">
                        <img src="../images/pilar3.webp" />
                        <h2>Philanthropy and Give Back</h2>
                        <p>
                            Philanthropy and Give back are part of our DNA. We support local and
                            global projects, such as “Humana ORG”, helping vulnerable women and
                            “Impactando Vidas Pretas” that fight against racism, including other
                            projects that also fight against gender inequality and that support
                            the development of women in all sectors.
                        </p>
                    </div>
                </div>
            </section>
			);
	}
}

export default Pilares;