
function Media(parametros) {

    if (parametros.aberto) {
        return (
            <></>
        );
    }

    const languageStrings = {
        "pt-br": {
            "titulo": "EVE na Mídia",
        },
        "en-us": {
            "titulo": "EVE on Media",
		}
	}

	return (
        <section className="flexCol darkblue-background page-block">
            <h1 style={{ marginTop: "1em" }}>{languageStrings[parametros.language].titulo}</h1>
            <div
                className="flexRow desktop"
                style={{
                    margin: "auto",
                    width: "85%",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <a target="_blank" href="https://forbes.com.br/forbes-tech/2022/05/de-discos-a-nfts-a-fotografa-brasileira-que-aposta-na-tokenizacao/"><img className="brand" src="../images/logos_media/Forbes-Logo.webp" alt="Forbes" /></a>
                <a target="_blank" href="https://revistapoder.uol.com.br/tag/projeto-eve/"><img className="brand" src="../images/logos_media/logo-revista-poder-online.webp" alt="Revista Poder" /></a>
                <a target="_blank" href="https://cointimes-com-br.cdn.ampproject.org/c/s/cointimes.com.br/primeira-dao-feminina-do-brasil-faz-pre-vendas-de-nft-em-nova-york/amp/"><img className="brand" src="../images/logos_media/cointimes.webp" alt="Coin Times" /></a>
                <a target="_blank" href="https://braziljournal.com/project-eve-quer-inserir-mulheres-no-mundo-cripto/"><img className="brand" src="../images/logos_media/brazil-journal.webp" alt="Brazil Journal" /></a>
                <a target="_blank" href="https://caras.uol.com.br/amp/atualidades/projeto-brasileiro-organizado-apenas-por-mulheres-democratiza-nfts-e-blockchain.phtml"><img className="brand" src="../images/logos_media/caras-logo.webp" alt="Caras" /></a>
                <a target="_blank" href="https://www.mitsloanreview.com.br/post/dao-brasileira-quer-trazer-as-mulheres-para-o-mundo-das-nfts"><img className="brand" src="../images/logos_media/mit-sloan.webp" alt="MIT Sloan" /></a>
                <a target="_blank" href="https://forbes.com.br/forbes-tech/2022/04/conheca-oprojeto-brasileiro-criada-por-mulheres-com-foco-em-democratizar-o-nft/"><img className="brand" src="../images/logos_media/Forbes-Logo.webp" alt="Forbes" /></a>
                <a target="_blank" href="https://www.abcdacomunicacao.com.br/elektra-e-a-mais-nova-integrante-da-eve-nft/"><img className="brand" src="../images/logos_media/logo-abc.webp" alt="ABC da Comunicação" /></a>
            </div>
            <div className="mobile" style={{ width: "90%" }}>
                <div className="founders-scroll">
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <a target="_blank" href="https://forbes.com.br/forbes-tech/2022/05/de-discos-a-nfts-a-fotografa-brasileira-que-aposta-na-tokenizacao/">
                            <img style={{ display: "flex", width: "100%" }} src="../images/logos_media/Forbes-Logo.webp" alt="Forbes" />
                        </a>
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <a target="_blank" href="https://revistapoder.uol.com.br/tag/projeto-eve/">
                            <img style={{ display: "flex", width: "100%" }} src="../images/logos_media/logo-revista-poder-online.webp" alt="Revista Poder" />
                        </a>
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <a target="_blank" href="https://cointimes-com-br.cdn.ampproject.org/c/s/cointimes.com.br/primeira-dao-feminina-do-brasil-faz-pre-vendas-de-nft-em-nova-york/amp/">
                            <img style={{ display: "flex", width: "100%" }} src="../images/logos_media/cointimes.webp" alt="Coin Times" />
                        </a>
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <a target="_blank" href="https://braziljournal.com/project-eve-quer-inserir-mulheres-no-mundo-cripto/">
                            <img style={{ display: "flex", width: "100%" }} src="../images/logos_media/brazil-journal.webp" alt="Brazil Journal" />
                        </a>
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <a target="_blank" href="https://caras.uol.com.br/amp/atualidades/projeto-brasileiro-organizado-apenas-por-mulheres-democratiza-nfts-e-blockchain.phtml">
                            <img style={{ display: "flex", width: "100%" }} src="../images/logos_media/caras-logo.webp" alt="Caras" />
                        </a>
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <a target="_blank" href="https://www.mitsloanreview.com.br/post/dao-brasileira-quer-trazer-as-mulheres-para-o-mundo-das-nfts">
                            <img style={{ display: "flex", width: "100%" }} src="../images/logos_media/mit-sloan.webp" alt="MIT Sloan" />
                        </a>
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <a target="_blank" href="https://forbes.com.br/forbes-tech/2022/04/conheca-oprojeto-brasileiro-criada-por-mulheres-com-foco-em-democratizar-o-nft/">
                            <img style={{ display: "flex", width: "100%" }} src="../images/logos_media/Forbes-Logo.webp" alt="Forbes" />
                        </a>
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <a target="_blank" href="https://www.abcdacomunicacao.com.br/elektra-e-a-mais-nova-integrante-da-eve-nft/">
                            <img style={{ display: "flex", width: "100%" }} src="../images/logos_media/logo-abc.webp" alt="ABC da Comunicação" />
                        </a>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </section>
		);
}

export default Media;