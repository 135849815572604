import { useState } from "react";

function BoxSuccess({ mensagem, aberto }) {

    const [hideLightbox, setHideLightbox] = useState(aberto);

    return (
        <div id="popup_message" className="popup" style={{ display: hideLightbox ? "flex" : "none" }}>
            <div className="popup-message concluded-message">
                <span
                    id="popup_message_close_button"
                    className="material-icons close-message"
                    onClick={() => setHideLightbox(false)}
                >
                    close
                </span>
                <span className="material-icons" style={{ fontSize: "5em", margin: "auto" }} >
                    check_circle_outline
                </span>
                <div id="popup_message_text" className="message-text">
                    {mensagem}
                </div>
            </div>
        </div>
    );
}

export default BoxSuccess;