function Apoiadores(parametros) {

    if (parametros.aberto) {
        return (
            <></>
        );
    }

    const languageStrings = {
        "pt-br": {
            "parceiros": "Parceiros",
            "apoiamos": "Apoiamos",
        },
        "en-us": {
            "parceiros": "Partners",
            "apoiamos": "We Support",
		}
	}

	return (
        <section className="flexCol darkblue-background page-block">
            <h1>{languageStrings[parametros.language].parceiros}</h1>
            <div
                className="flexRow desktop"
                style={{
                    margin: "auto",
                    width: "85%",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <img className="brand-parceiros" src="../images/parceiros/parceiro1.webp" alt=".StartSe" />
                <img className="brand-parceiros" src="../images/parceiros/parceiro2.webp" alt="Hashdex" />
                <img className="brand-parceiros" src="../images/parceiros/parceiro3.webp" alt="MBM" />
                <img className="brand-parceiros" src="../images/parceiros/parceiro4.webp" alt="We For Woman" />
                <img className="brand-parceiros" src="../images/parceiros/parceiro5.webp" alt="Todas Group" />
                <img className="brand-parceiros" src="../images/parceiros/parceiro6.webp" alt="Belas Artes" />
                <img className="brand-parceiros" src="../images/parceiros/parceiro7.png" alt="Vex Authentics" />
                <img className="brand-parceiros" src="../images/parceiros/parceiro8.png" alt="Clubzz" />
            </div>
            <div className="mobile" style={{ width: "90%" }}>
                <div className="founders-scroll">
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img
                            style={{ display: "flex", width: "100%" }}
                            src="../images/parceiros/parceiro1.webp"
                            alt=".StartSe"
                        />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img
                            style={{ display: "flex", width: "100%" }}
                            src="../images/parceiros/parceiro2.webp"
                            alt="Hashdex"
                        />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img
                            style={{ display: "flex", width: "100%" }}
                            src="../images/parceiros/parceiro3.webp"
                            alt="MBM"
                        />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img
                            style={{ display: "flex", width: "100%" }}
                            src="../images/parceiros/parceiro4.webp"
                            alt="We For Woman"
                        />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img
                            style={{ display: "flex", width: "100%" }}
                            src="../images/parceiros/parceiro5.webp"
                            alt="Todas Group"
                        />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img
                            style={{ display: "flex", width: "100%" }}
                            src="../images/parceiros/parceiro6.webp"
                            alt="Belas Artes"
                        />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img
                            style={{ display: "flex", width: "100%" }}
                            src="../images/parceiros/parceiro7.png"
                            alt="Vex Authentics"
                        />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img
                            style={{ display: "flex", width: "100%" }}
                            src="../images/parceiros/parceiro8.png"
                            alt="Clubzz"
                        />
                    </div>
                </div>
            </div>
            <h1 style={{ marginTop: "3em" }}>{languageStrings[parametros.language].apoiamos}</h1>
            <div
                className="flexRow desktop"
                style={{
                    margin: "auto",
                    width: "85%",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <img className="brand" src="../images/apoiamos/apoiamos1.webp" alt="Humana" />
                <img className="brand" src="../images/apoiamos/apoiamos2.webp" alt="Mulheres no e-Commerce" />
                <img className="brand" src="../images/apoiamos/apoiamos3.webp" alt="Capacita Me" />
                <img className="brand" src="../images/apoiamos/apoiamos4.webp" alt="Impactando Vidas Pretas" />
            </div>
            <div className="mobile" style={{ width: "90%" }}>
                <div className="founders-scroll">
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img style={{ display: "flex", width: "100%" }} src="../images/apoiamos/apoiamos1.webp" alt="Humana" />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img style={{ display: "flex", width: "100%" }} src="../images/apoiamos/apoiamos2.webp" alt="Mulheres no e-Commerce" />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img style={{ display: "flex", width: "100%" }} src="../images/apoiamos/apoiamos3.webp" alt="Capacita Me" />
                    </div>
                    <div className="brand-mobile">
                        <div style={{ flex: 1 }} />
                        <img style={{ display: "flex", width: "100%" }} src="../images/apoiamos/apoiamos4.webp" alt="Impactando Vidas Pretas" />
                    </div>
                </div>
            </div>
        </section>
		);

}

export default Apoiadores;