/*
// Debug only!
console.log({ contractAddress });
console.log({ rpcUrlInfuraRinkeby });
console.log({ mailchimp_url });
*/

import {
	init,
} from "@web3-onboard/react";

import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import coinbaseWalletModule from '@web3-onboard/coinbase'

import MainContractMetaData from "../../mainContractABI.json";
import MintPassContractMetaData from "../../MintPassABI_metadata.json";

const { mainContractAddress } = require("../../secrets.json");
const { mintPassContractAddress } = require("../../secrets.json");
const { rpcUrlInfuraRinkeby } = require("../../secrets.json");
const { rpcUrlInfuraMainnet } = require("../../secrets.json");

var rpcInfura;

const injected = injectedModule();
const walletConnect = walletConnectModule({
	qrcodeModalOptions: {
		mobileLinks: ["metamask", "argent", "trust", "imtoken", "pillar"],
	},
});
const coinbaseWalletSdk = coinbaseWalletModule();

const parametrosWeb3Onboard = {
	wallets: [walletConnect, injected, coinbaseWalletSdk],
	appMetadata: {
		name: "EVE",
		icon: "<svg><svg/>",
		description: "EVE",
		recommendedInjectedWallets: [
			{ name: "MetaMask", url: "https://metamask.io" },
			{ name: "Coinbase", url: "https://wallet.coinbase.com/" },
		],
	},
};

const ambiente = "mainnet";         // "rinkeby" ou "mainnet"

if (ambiente == "rinkeby") {
	parametrosWeb3Onboard.chains = [
		{
			id: "0x4",
			token: "rETH",
			label: "Ethereum Rinkeby Testnet",
			rpcUrl: rpcUrlInfuraRinkeby
		}
	];

	rpcInfura = rpcUrlInfuraRinkeby;
}
else {
	parametrosWeb3Onboard.chains = [
		{
			id: "0x1",
			token: "ETH",
			label: "Ethereum",
			rpcUrl: rpcUrlInfuraMainnet
		}
	];

	rpcInfura = rpcUrlInfuraMainnet;
}

let web3Onboard = init(parametrosWeb3Onboard);

console.log(web3Onboard.state.get());

export { rpcInfura, ambiente, mainContractAddress, mintPassContractAddress, web3Onboard, MainContractMetaData, MintPassContractMetaData }