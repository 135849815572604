function About(parametros) {

    if (parametros.aberto) {
        return (
            <></>
        );
    }

    // Atenção! O Texto já deve vir formatado. Cuidado com o que é colocado lá.

    const languageStrings = {
        "pt-br": {
            "titulo": "Sobre a EVE",
            "texto": "Uma DAO construída por mulheres que são referência nas áreas de tecnologia, cultura, empreendedorismo e mercado financeiro, juntas acreditamos que é possível criar uma Web 3.0 inclusiva e representativa. Estamos tomando iniciativas para aumentar a diversidade através da educação e independência financeira, criando oportunidades para que qualquer pessoa em todo o mundo seja proprietária, criadora e colaboradora nesta nova era da internet.",
        },
        "en-us": {
            "titulo": "About",
            "texto": "A DAO built by women that are a reference in the tech industry and the financial market, believing that together it is possible to create a WEB 3.0 that is both inclusive and receptive.<br />Taking initiative to increase diversity in the NFT world through educational and financial independence, we create opportunities so that everyone, anywhere, can be able to create, collaborate and be part of this new era.",
        }
    }

	return (
        <section
            id="about"
            className="flexCol page-block"
            style={{ background: "#6E00A8", padding: "2em 0" }}
        >
            <h1>{languageStrings[parametros.language].titulo}</h1>
            <p style={{ width: "80%" }} dangerouslySetInnerHTML={{ __html: languageStrings[parametros.language].texto }}></p>
        </section>
		);
}

export default About;