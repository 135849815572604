import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import App_ptbr from "./pages/App_ptbr";
import App_enus from "./pages/App_enus";
//import MintPass_ptbr from "./pages/MintPass_ptbr";
//import MintPass_enus from "./pages/MintPass_enus";

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<App_ptbr />} />
					<Route path="pt-br" element={<App_ptbr />} />
					<Route path="en-us" element={<App_enus />} />
				</Route>
				<Route path="/pt-br" element={<App_ptbr />} />
				<Route path="/en-us" element={<App_enus />} />
			</Routes>
		</BrowserRouter>
	);
}

ReactDOM.render(<App />, document.getElementById("root"));