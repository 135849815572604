import { useRef } from "react";
import { useState } from "react";

import styled from "styled-components";
import Swal from "sweetalert2";

const MintButton = styled.div``;
const MintQuantity = styled.input``;

function MintPanel(props) {
	const languageStrings = {
		"pt-br": {
			"zero_tokens_title": "Quantidade � Zero",
			"zero_tokens_message": "Por favor selecione uma quantidade maior que zero",
			"botao_mint": "Pagar com Ethereum",
		},
		"en-us": {
			"zero_tokens_title": "Cannot Mint Zero Tokens",
			"zero_tokens_message": "Please Mint at least one Token",
			"botao_mint": "Pay with Ethereum",
		}
	}

	const [textInvalidState, setTextInvalidState] = useState(false);
	const [batchSizeState, setBatchSizeState] = useState(1);

	const handleValidation = (e) => {
		const reg = new RegExp("^[0-9]*$");
		if (reg.test(e.target.value)) {
			if (e.target.value > props.remainingMintable) {
				setBatchSizeState(props.remainingMintable);
				return;
			}
			if (e.target.value <= 1) {
				setBatchSizeState(1);
				return;
			} else {
				setBatchSizeState(e.target.value);
			}
		} else {
			return;
		}
	};
	let quantityRef = useRef(0);
	const mint = props.mint;

	return (
		<>
			<div className="mint-input">
				<span id="mint_button_remove" className="material-icons mint-symbol" style={{ marginLeft: 5 + "px" }}
					onClick={() => {
						if (batchSizeState > 1) {
							setBatchSizeState(batchSizeState - 1);
						}
					}}
				>remove</span>
				<MintQuantity id="outlined-basic" className="mint-number" placeholder="1"
					onChange={handleValidation}
					value={batchSizeState}
					inputRef={quantityRef}
					error={textInvalidState}
				/>
				<span id="mint_button_add" className="material-icons mint-symbol" style={{ marginRight: 5 + "px" }}
					onClick={() => {
						if (batchSizeState < props.remainingMintable) {
							setBatchSizeState(batchSizeState + 1);
						}
					}}
				>add</span>
			</div>
			<MintButton className="mint-button" style={{ marginBottom: 25 }} disabled={props.remainingMintable === 0}
				onClick={() => {
					if (quantityRef.current.value < 1) {
						Swal.fire(languageStrings[props.language].zero_tokens_title, languageStrings[props.language].zero_tokens_message, 'error');
						return
					}

					console.log(batchSizeState)

					mint(batchSizeState);
				}}>
				{languageStrings[props.language].botao_mint}
			</MintButton>
		</>
	);
}

export default MintPanel;