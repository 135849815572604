function BoxProgress({ mensagem, aberto }) {
    // Esta caixa n�o tem o bot�o para ser fechado.
    return (
        <div id="popup_minting" className="popup" style={{ display: aberto ? "flex" : "none" }}>
            <div className="popup-message minted-message">
                <span
                    id="popup_error_close_button"
                    className="material-icons close-message"
                >
                    close
                </span>
                <span className="material-icons" style={{ fontSize: "5em", margin: "0.25em auto" }} >
                    settings
                </span>
                <div id="popup_error_text" className="message-text">
                    {mensagem}
                </div>
            </div>
        </div>
    );
}

export default BoxProgress;