import styled from "styled-components";

import * as Web3Onboard from "../Web3Onboard/Web3Onboard";
import MintPanel from "./MintPanel";
import { useState } from "react";
import ReactTooltip from "react-tooltip";

function BlocoMintagem({ language, aberto, MaxMintsAvailable, remainingSupplyState, tokenState, isPublicMintActive, limitPerWalletState, priceInEther, connect, wallet, mint, isMintPass, allowlistData }) {

	const remainingForWallet = (tokenState !== null) ? (limitPerWalletState - tokenState) : limitPerWalletState;
	const remainingMintable = (remainingSupplyState < remainingForWallet) ? remainingSupplyState : remainingForWallet;

	var iframeGatewayURI;
	var baseToken;

	if (isMintPass) {
		iframeGatewayURI = "https://itsramp.io/itspayment?token=NWU2NDU0ZWY1OTMzYTk0ZGI3YTljM2UyZTRhMTg3YTE6MzJiMTgzMWI1YTlmYmY5ODJmZDdhNjRlNmYzMGU2OWU=&address_contract=0xe454b857d2eaf8895f8c6c40319637a1581519e9";
		baseToken = "../images/mintpass/mintpass.webp";
	}
	else {
		iframeGatewayURI = "https://dev.itspay.io/itspayment?token=MGU2MTc5OGUxZDc3NDg2OTgxNDFmZTRjOWE3YjMzYTM6MmQwMjY3ZDIwNDI4MjhmN2JjYmZlODk5MmNlZDBjMGU=&wallet_address=0x3f7d209D0C64a4Bb5A236b6F6B801dACA5a03FEC";
		baseToken = "../images/base_token.webp";
	}

	//console.log(iframeGatewayURI);

	var languageStrings = {
		"pt-br": {
			"venda_publica": "VENDA PÚBLICA",
			"venda_publica_mint_pass": "MINT PASS",
			"total_de_itens": "Total de Itens",
			"itens_restantes": "Itens Restantes",
			"itens_restantes_wallet": "",                   // Ver o bloco adiante
			"valor_do_item": "Valor",
			"status_cunhagem": (isPublicMintActive ? "MINTING ABERTO" : "MINTING FECHADO"),
			"status_wallet": (wallet ? "CONECTADO" : "CONECTAR WALLET"),

			"pagamento_pix": "Pagar via PIX",
			"pagamento_cripto": "Pagar com Cripto",

			"suprimento": "Mint Pass Disponível: ",
			"botao_alt_pix": "O portal de pagamentos do Parceiro MobiUp será carregado acima.",
			"botao_alt_crypto": "O portal de pagamentos do Parceiro MobiUp será fechado.",

			"conferencia": "Durante o pagamento com PIX, favor conferir a quantidade de NFTs",
		},
		"en-us": {
			"venda_publica": "PUBLIC SALE",
			"venda_publica_mint_pass": "MINT PASS",
			"total_de_itens": "Total Supply",
			"itens_restantes": "Remaining Supply",
			"itens_restantes_wallet": "",                   // Ver o bloco adiante
			"valor_do_item": "Value",
			"status_cunhagem": (isPublicMintActive ? "MINTING LIVE" : "MINTING CLOSED"),
			"status_wallet": (wallet ? "CONNECTED " : "CONNECT TO WALLET"),

			"pagamento_pix": "Pay using PIX",
			"pagamento_cripto": "Pay with Crypto",

			"suprimento": "Mint Pass Available: ",
			"botao_alt_pix": "Our partner's payment gateway will be shown above.",
			"botao_alt_crypto": "Our partner's payment gateway will be closed.",

			"conferencia": "If buying using PIX, please check carefully the amount selected",
		}
	}

	// Lógica de decisão do texto da quantidade.
	if (tokenState === null) {
		// Não está conectado
		languageStrings["pt-br"].itens_restantes_wallet = "Para esta Wallet: Conecte a Wallet";
		languageStrings["en-us"].itens_restantes_wallet = "For this Wallet: Connect Wallet";
	}
	else {
		var texto_pt = "Para esta Wallet: ";
		var texto_en = "For this Wallet: ";

		// Está conectado.
		if (limitPerWalletState - tokenState >= 0) {
			// A quantidade restante
			texto_pt += (limitPerWalletState - tokenState).toString();
			texto_en += (limitPerWalletState - tokenState).toString();
		}
		else {
			// Poderia ser "quota esgotada" ou coisa do tipo
			texto_pt += "0";
			texto_en += "0";
		}

		if (tokenState == 0) {
			texto_pt += " (você não tem nenhum ainda)";
			texto_en += " (you don't own any yet)";
		}
		else {
			texto_pt += " (você já tem " + tokenState.toString() + ")";
			texto_en += " (own " + tokenState.toString() + ")";
		}

		languageStrings["pt-br"].itens_restantes_wallet = texto_pt;
		languageStrings["en-us"].itens_restantes_wallet = texto_en;
	}

	const vendaTexto = isMintPass == "true" ? languageStrings[language].venda_publica_mint_pass : languageStrings[language].venda_publica;
	const [formaPagamento, setFormaPagamento] = useState("cripto");

	if (aberto) {

		return (
			<>
				{/* Início do bloco para cripto */}
				<div style={{ background: "red", width: "100%", display: formaPagamento == "cripto" ? "block" : "none" }}>
					<section className="flexCol mint-background page-block">
						<h1>{vendaTexto}</h1>
						<p>
							<b>
								{languageStrings[language].suprimento} {remainingSupplyState}/400<br />
								{languageStrings[language].itens_restantes_wallet}<br />
								{languageStrings[language].valor_do_item}: {priceInEther} Ether + Fee
							</b>
						</p>
						<h2 style={{ color: "#F113B3" }}>{languageStrings[language].status_cunhagem}</h2>
						<img style={{ width: "30%" }} src={baseToken} />
						<ConnectButton className="mint-button connect-button"
							onClick={async () => {
								console.log(Web3Onboard.web3Onboard.state.get());
								await connect();
							}}
						>
							{languageStrings[language].status_wallet}
						</ConnectButton>
						<MintPanel mint={mint} language={language} remainingMintable={remainingMintable} />
						<button
							data-tip data-for="pay_with_pix_tip"
							id="pay_with_pix"
							className="mint-button"
							style={{ marginBottom: 25, marginTop: 12 }}
							onClick={() => setFormaPagamento("pix")}
						>
							{languageStrings[language].pagamento_pix}
						</button>
						<ReactTooltip id="pay_with_pix_tip" place="bottom" effect="solid">
							{languageStrings[language].botao_alt_pix}
						</ReactTooltip>
						{languageStrings[language].conferencia}
					</section>
				</div>
				{/* Final do bloco para cripto */}
				{/* Início do bloco para Pix */}
				<div style={{ background: "red", width: "100%", display: formaPagamento == "pix" ? "block" : "none" }}>
					<section className="flexCol mint-background page-block">
						<h1>{vendaTexto}</h1>
						<p>
							<b>
								{languageStrings[language].suprimento} {remainingSupplyState}/400<br />
								{languageStrings[language].itens_restantes_wallet}<br />
								{languageStrings[language].valor_do_item}: {priceInEther} Ether + Fee
							</b>
						</p>
						<h2 style={{ color: "#F113B3" }}>{languageStrings[language].status_cunhagem}</h2>
						<img style={{ width: "30%" }} src={baseToken} />
						<iframe style={{ marginTop: 25 }} className="iframeGateway" id="iframeGateway" src={iframeGatewayURI} width="100%" height="650px" frameBorder="0"></iframe>
						<button
							data-tip data-for="pay_with_crypto_tip"
							id="pay_with_crypto"
							className="mint-button"
							style={{ marginBottom: 25, marginTop: 12 }}
							onClick={() => setFormaPagamento("cripto")}
							tooltip={languageStrings[language].botao_alt_crypto}
						>
							{languageStrings[language].pagamento_cripto}
						</button>
						<ReactTooltip id="pay_with_crypto_tip" place="bottom" effect="solid">
							{languageStrings[language].botao_alt_crypto}
						</ReactTooltip>
						{languageStrings[language].conferencia}
					</section>
				</div>
				{/* Final do bloco para Pix */}
			</>
		);
	}
	else {
		return (
			<></>
		);
	}
}

const ConnectButton = styled.div`
`;

export default BlocoMintagem;