//import { Link } from "react-router-dom";

function Roadmap(parametros) {

    if (parametros.aberto) {
        return (
            <></>
            );
	}

	if (parametros.language == "pt-br") {
		return (
            <section className="flexCol darkblue-background page-block">
                <h1 style={{ marginTop: 50 }}>Roadmap</h1>
                <div className="flexCol roadmap_box roadmap-desktop">
                    <div className="roadmap_line" />
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>MINT PASS</b>
                        </h4>
                        <p className="phase-description">
                            Iniciaremos as vendas da nossa coleção com <b>400 Mint Passes</b> muito especiais! O Mint Pass garante o mint de alguns NFTs exclusivos da nossa coleção para integrantes ativas da comunidade e parceiras selecionadas. Apenas os NFTs mintados a partir do Mint Pass terão utilities exclusivas, então fique ligado e garanta a sua vaga na Allowlist!
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>20% das vendas</b>
                        </h4>
                        <p className="phase-description">
                            <b>Giveaway </b>de NFTs para a comunidade: Prezamos por inclusão, acessibilidade e comunidade! Por isso queremos dar a <b>oportunidade</b> para que mulheres que não têm recursos disponíveis para investir em NFTs possam <b>fazer parte da EVE</b> e dessa comunidade maravilhosa que estamos construindo!
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>40% das vendas</b>
                        </h4>
                        <p className="phase-description">
                            Acesso a <b>conteúdos, mentorias e workshops</b>: Nossas Founders são
                            referências em suas áreas de atuação e prepararam conteúdos incríveis
                            sobre{" "}
                            <b>
                                Finanças, Marketing, Growth, Cripto, Empreendedorismo, Fotografia,
                                Música, Tecnologia e Inovação
                            </b>{" "}
                            para nossas holders! Elas vão compartilhar muito do que aprenderam
                            durante suas vidas e o que fez diferença para suas carreiras até aqui.
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>60% das vendas</b>
                        </h4>
                        <p className="phase-description">
                            <b>Charity:</b> Igualdade de gênero é umas das pautas principais da
                            EVE. Sabemos que podemos impactar a vida de muitas mulheres através do
                            nosso projeto, mas nem todas… Por isso vamos doar 5% do valor
                            arrecadado para diferentes{" "}
                            <b>
                                ONGs que combatem a desigualdade de gênero, desigualdade social e racial,
                                entre outras.
                            </b>{" "}
                            Dessa maneira, contribuímos para pautas importantes que vão além do
                            nosso escopo de atuação.
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>80% das vendas</b>
                        </h4>
                        <p className="phase-description">
                            <b>Lançamento </b>de produtos físicos e digitais da linha exclusiva
                            EVE: Estamos preparando grandes surpresas aqui!{" "}
                            <b>
                                Produtos exclusivos, grandes parcerias e experiências únicas para
                                nossa comunidade.{" "}
                            </b>
                            Acredite, você vai querer muito fazer parte!
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>100% das vendas</b>
                        </h4>
                        <p className="phase-description">
                            <b>Convenção e inauguração da nossa Fundação EVE:</b> fundação que
                            empoderará mulheres a fazerem parte da WEB3.0, ajudando na realização
                            de seus sonhos, tendo seus projetos financeiramente apoiados pela
                            Fundação EVE. Junto com um evento exclusivo, com grandes atrações e
                            experiências para fortalecer ainda mais a nossa comunidade.
                        </p>
                    </div>
                </div>
            </section>
		);
	}
	else {
		return (
            <section className="flexCol darkblue-background page-block">
                <h1 style={{ marginTop: 50 }}>Roadmap</h1>
                <div className="flexCol roadmap_box roadmap-desktop">
                    <div className="roadmap_line" />
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>MINT PASS</b>
                        </h4>
                        <p className="phase-description">
                            We will start the sale of our collection with <b>400 very special Mint Passes</b>! The Mint Pass allows the minting of exclusive NFTs of our collection for active members of our community and selected partners. Only NFTs minted from the Mint Pass will have access to exclusive utilities, so stay tuned and ensure your spot on our Allowlist!
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>20% sold</b>
                        </h4>
                        <p className="phase-description">
                            <b>Special giveaway:</b> we want to give the <b>opportunity</b> to
                            women, who do not have the resources to invest in NFT yet,{" "}
                            <b>to be a part of EVE.</b>
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>40% sold</b>
                        </h4>
                        <p className="phase-description">
                            Access to <b>contents, mentorships and workshops:</b> our founders are
                            experts in their area and prepared exclusive contents about{" "}
                            <b>Finance, Marketing, Crypto, Photography, Music and Technology</b>{" "}
                            to our holders.
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>60% sold</b>
                        </h4>
                        <p className="phase-description">
                            <b>Charity:</b> gender equality is one of our principals here at EVE.
                            That's why we donate 5% of the amount we raise to{" "}
                            <b>ONGs that fight against gender inequality.</b>
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>80% sold</b>
                        </h4>
                        <p className="phase-description">
                            <b>Launching</b> of collabs products:{" "}
                            <b>
                                Exclusive products, huge partnerships and unique experiences for our
                                community.
                            </b>
                        </p>
                    </div>
                    <div className="flexRow phase-col phase">
                        <div className="phase-circle" />
                        <h4 className="phase-title">
                            <b>100% sold</b>
                        </h4>
                        <p className="phase-description">
                            <b>EVE Convention and launching of EVE Foundation:</b> Foundation that
                            empowers women to be part of Web3.0
                        </p>
                    </div>
                </div>
            </section>
		);
	}
}

export default Roadmap;